header {
  position: relative;
  text-align: center;
}
h1 {
  color: white; /* White text */
  padding: 10px;
  border-radius: 5px;
}

.highlight {
  color: #31b8d4; /* Change to blue for the letters B, U, T, T */
  font-weight: bold; /* Optionally bold the highlighted letters */
}

.slogan {
  font-size: 2rem; /* Adjust size for prominence */
  font-style: italic; /* Make it italic */
  color: white; /* Change as per your design */
  margin-top: 0.5rem; /* Add some space between title and slogan */
}

.header-container {
  position: relative;
}

.gallery-image {
  width: 100%; /* Adjust as necessary */
  max-height: 100%; /* Maintain aspect ratio */
  display: block; /* Remove bottom space from image */
  object-fit: cover;
}

.text-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Black background with 50% opacity */
  border-radius: 2%;
  position: absolute;
  top: 50%; /* Center the text vertically */
  left: 50%; /* Center the text horizontally */
  transform: translate(-50%, -50%); /* Adjust for text size */
  color: white; /* Change to your desired text color */
  text-align: center; /* Center the text */
}
